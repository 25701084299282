import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import isEqual from 'lodash/isEqual.js'
import pick from 'lodash/pick.js'

import {
  AMAZON_CA,
  AMAZON_UK,
} from '../../../common/constants/CartVendorOptions.js'
import {
  dispatch,
  formsSelector,
  getState,
  setForm,
  updateForm,
} from '../../../store.js'
import api from '../../../common/api.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import {navigate} from '../../../common/location.js'
import {
  SETTINGS,
  SUPPLIERS,
  SUPPLIER_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import {locationSelector} from '../../../redux/selectors/ui/location.js'
import {showGlobalError} from '../../GlobalErrorMessage.js'
import {
  setWarehouse,
  WAREHOUSE_TYPE_AWD,
  WAREHOUSE_TYPE_FBA,
} from '../../../data/warehouses.js'
import {
  setSupplier,
  supplierSelector,
  isFBASupplierSelector,
} from '../../../data/suppliers.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import {
  COMMUNICATION_EMAIL,
  COMMUNICATION_NONE,
} from '../../../common/constants/Suppliers.js'
import {
  useDropshippingSelector,
  usePurchaseOrdersSelector,
} from '../../../data/company.js'
import {getCarts} from '../../../data/carts.js'

export const SupplierFormShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  company: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  referenceNumber: PropTypes.string,
  vendor_config: PropTypes.object,
  archive_date: PropTypes.string,
  automatically_show_ship_to_email: PropTypes.bool,
  automatically_show_supplier_price: PropTypes.bool,
  dropship_prefer_cart_order_line_names: PropTypes.bool,
  default_shipping_method: PropTypes.string,
  default_shipping_carrier: PropTypes.string,
  default_shipping_account: PropTypes.string,
  shipping_method_maps: PropTypes.array,
  warehouse_id: PropTypes.string,
  dropshipment_routing_channel: PropTypes.string.isRequired,
  dropshipment_routing_address: PropTypes.string.isRequired,
  po_routing_channel: PropTypes.string.isRequired,
  po_routing_address: PropTypes.string.isRequired,
  isSaving: PropTypes.bool,
  serverError: ErrorsShape,
})

export const SETTINGS_SUPPLIERS = 'SETTINGS_SUPPLIERS'
const initialForm = {
  id: null,
  company: '',
  name: '',
  phone: '',
  fax: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  referenceNumber: '',
  automatically_show_ship_to_email: false,
  automatically_show_supplier_price: false,
  dropship_prefer_cart_order_line_names: true,
  default_shipping_method: '',
  default_shipping_carrier: '',
  default_shipping_account: '',
  shipping_method_maps: [],
  warehouse_id: '',
  dropshipment_routing_channel: COMMUNICATION_EMAIL,
  dropshipment_routing_address: '',
  po_routing_channel: COMMUNICATION_EMAIL,
  po_routing_address: '',
  isSaving: false,
  serverError: null,
}

export function setupSuppliersForm() {
  return {
    formName: SETTINGS_SUPPLIERS,
    initialForm,
  }
}

export function setupForEdit() {
  const supplierID = settingsSupplierIDSelector(getState())
  const supplier = supplierSelector(getState(), {supplierID})

  if (!supplier) {
    return
  }

  setForm(SETTINGS_SUPPLIERS, {
    ...initialForm,
    id: supplier.id,
    company: supplier.address.company || '',
    name: supplier.address.name || '',
    phone: supplier.address.phone || '',
    fax: supplier.address.fax || '',
    street1: supplier.address.street1 || '',
    street2: supplier.address.street2 || '',
    city: supplier.address.city || '',
    state: supplier.address.state || '',
    zip: supplier.address.zip || '',
    country: supplier.address.country || '',
    referenceNumber: supplier.address.reference_number || '',
    automatically_show_ship_to_email: supplier.automatically_show_ship_to_email,
    automatically_show_supplier_price:
      supplier.automatically_show_supplier_price,
    dropship_prefer_cart_order_line_names:
      supplier.dropship_prefer_cart_order_line_names,
    default_shipping_method: supplier.default_shipping_method || '',
    default_shipping_carrier: supplier.default_shipping_carrier || '',
    default_shipping_account: supplier.default_shipping_account || '',
    shipping_method_maps: supplier.shipping_method_maps || [],
    warehouse_id: supplier.warehouse_id ? String(supplier.warehouse_id) : '',
    dropshipment_routing_channel:
      supplier.dropshipment_routing_channel || COMMUNICATION_EMAIL,
    dropshipment_routing_address: supplier.dropshipment_routing_address || '',
    po_routing_channel: supplier.po_routing_channel || COMMUNICATION_EMAIL,
    po_routing_address: supplier.po_routing_address || '',
  })
}

export function setupForAdding() {
  setForm(SETTINGS_SUPPLIERS, {...initialForm, id: NEW_ID})
}

export function resetSuppliersForm() {
  setForm(SETTINGS_SUPPLIERS, initialForm)
}

export function updateSuppliersForm(...args) {
  updateForm(SETTINGS_SUPPLIERS, ...args)
}

export function suppliersFormSelector(state) {
  return (
    formsSelector(state)[SETTINGS_SUPPLIERS] || suppliersFormSelector.default
  )
}
suppliersFormSelector.default = {}

export function isFBASupplierFormSelector(state) {
  const {id} = suppliersFormSelector(state)

  return isFBASupplierSelector(state, {supplierID: id})
}

export const errorsSelector = createSelector(
  suppliersFormSelector,
  isFBASupplierFormSelector,
  useDropshippingSelector,
  usePurchaseOrdersSelector,
  (
    {
      company,
      shipping_method_maps,
      dropshipment_routing_channel,
      dropshipment_routing_address,
      po_routing_channel,
      po_routing_address,
      serverError,
    },
    isFBASupplier,
    useDropshipping,
    usePurchaseOrders,
  ) => {
    const errors = {}

    if (serverError && ['company'].includes(serverError.param)) {
      errors[serverError.param] =
        serverError.error_message || serverError.message
    }

    if (serverError && isEmpty(errors)) {
      errors.serverError = `${
        serverError.error_message || serverError.message
      }${serverError.param ? `: ${serverError.param}` : ''}`
    }

    if (!isPresent(company)) {
      errors.company = 'Supplier name is required'
      errors.preventSave = true
    }

    if (
      dropshipment_routing_channel === COMMUNICATION_EMAIL &&
      !isFBASupplier &&
      !isPresent(dropshipment_routing_address) &&
      useDropshipping
    ) {
      errors.dropshipment_routing_address = 'Email is required'
      errors.preventSave = true
    }

    if (
      po_routing_channel === COMMUNICATION_EMAIL &&
      !isFBASupplier &&
      !isPresent(po_routing_address) &&
      usePurchaseOrders
    ) {
      errors.po_routing_address = 'Email is required'
      errors.preventSave = true
    }

    shipping_method_maps.reduce(
      (
        prev,
        {
          requested_shipping_method,
          mapped_shipping_method,
          mapped_shipping_carrier,
          mapped_shipping_account,
        },
        index,
      ) => {
        if (!isPresent(requested_shipping_method)) {
          errors[`shipping_method_maps_${index}`] =
            'Requested shipping is required'

          errors.preventSave = true
        } else if (prev[requested_shipping_method]) {
          errors[`shipping_method_maps_${index}`] =
            'This mapping already exists'

          errors.preventSave = true
        } else if (
          !isPresent(mapped_shipping_method) &&
          !isPresent(mapped_shipping_carrier) &&
          !isPresent(mapped_shipping_account)
        ) {
          errors[`shipping_method_maps_${index}`] = 'A map to value is required'

          errors.preventSave = true
        }

        prev[requested_shipping_method] = true

        return prev
      },
      {},
    )

    return errors
  },
)

export const settingsSupplierIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === SUPPLIERS &&
      isPositiveNumeric(pathComponents[2])
    ) {
      return Number(pathComponents[2])
    }

    return null
  },
)

export const settingsSupplierAddSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    return (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === SUPPLIERS &&
      pathComponents[2] === NEW_ID
    )
  },
)

export const suppliersHashSelector = createSelector(
  settingsSupplierIDSelector,
  settingsSupplierAddSelector,
  (supplierID, isNew) => {
    if (isNew) {
      return `${SUPPLIER_SETTINGS_LINK}/${NEW_ID}`
    }

    if (supplierID) {
      return `${SUPPLIER_SETTINGS_LINK}/${supplierID}/edit`
    }

    return SUPPLIER_SETTINGS_LINK
  },
)

export function addNewShippingMapping() {
  const form = suppliersFormSelector(getState())
  const isFBASupplier = isFBASupplierFormSelector(getState())

  const lastRow = form.shipping_method_maps[
    form.shipping_method_maps.length - 1
  ] || {
    mapped_shipping_method: isFBASupplier ? 'Expedited' : '',
    mapped_shipping_carrier: '',
    mapped_shipping_account: '',
  }

  updateSuppliersForm({
    shipping_method_maps: [
      ...form.shipping_method_maps,
      {
        ...lastRow,
        id: null,
        active: true,
        requested_shipping_method: '',
      },
    ],
  })
}

export function updateShippingMapping(index, key, value) {
  const form = suppliersFormSelector(getState())

  updateSuppliersForm({
    shipping_method_maps: form.shipping_method_maps.map(
      (shipping_method_map, i) => {
        if (i !== index) {
          return shipping_method_map
        }

        return {
          ...shipping_method_map,
          [key]: value,
        }
      },
    ),
    [`hasChanged_shipping_method_maps_${index}_${
      key === 'requested_shipping_method'
        ? 'requested_shipping_method'
        : 'mapped_to'
    }`]: true,
  })
}

export function getShippingMappingError(form, errors, index) {
  return (
    (form[
      `hasChanged_shipping_method_maps_${index}_requested_shipping_method`
    ] ||
      form[`hasChanged_shipping_method_maps_${index}_mapped_to`]) &&
    errors[`shipping_method_maps_${index}`]
  )
}

export function removeShippingMapping(index) {
  const form = suppliersFormSelector(getState())

  updateSuppliersForm({
    shipping_method_maps: form.shipping_method_maps.filter(
      (_, i) => i !== index,
    ),
    [`shipping_method_maps_${index}_requested_shipping_method`]: false,
    [`shipping_method_maps_${index}_mapped_to`]: false,
  })
}

export async function saveSupplier() {
  const {
    id,
    company,
    name,
    phone,
    fax,
    street1,
    street2,
    city,
    state,
    zip,
    country,
    referenceNumber,
    automatically_show_ship_to_email,
    automatically_show_supplier_price,
    dropship_prefer_cart_order_line_names,
    default_shipping_method,
    default_shipping_carrier,
    default_shipping_account,
    shipping_method_maps,
    warehouse_id,
    dropshipment_routing_channel,
    dropshipment_routing_address,
    po_routing_channel,
    po_routing_address,
  } = suppliersFormSelector(getState())
  const isFBASupplier = isFBASupplierFormSelector(getState())
  const useDropshipping = useDropshippingSelector(getState())
  const usePurchaseOrders = usePurchaseOrdersSelector(getState())

  const params = isFBASupplier
    ? {
        company,
        ...(useDropshipping
          ? {default_shipping_method: default_shipping_method || null}
          : null),
      }
    : {
        company,
        name,
        phone,
        fax,
        street1,
        street2,
        city,
        state,
        zip,
        country,
        reference_number: referenceNumber,
        ...(useDropshipping
          ? {
              default_shipping_method: default_shipping_method || null,
              default_shipping_carrier: default_shipping_carrier || null,
              default_shipping_account: default_shipping_account || null,
              warehouse_id: warehouse_id ? Number(warehouse_id) : null,
              dropshipment_routing_channel,
              dropshipment_routing_address,
              automatically_show_ship_to_email,
              automatically_show_supplier_price,
              dropship_prefer_cart_order_line_names,
            }
          : null),
        ...(usePurchaseOrders
          ? {
              po_routing_channel,
              po_routing_address,
            }
          : null),
      }

  if (!usePurchaseOrders) {
    params.po_routing_channel = COMMUNICATION_NONE
  }

  if (!useDropshipping) {
    params.dropshipment_routing_channel = COMMUNICATION_NONE
  }

  const isNew = id === NEW_ID

  updateSuppliersForm({
    serverError: null,
    isSaving: true,
  })

  try {
    const {json} = isNew
      ? await api.post('/supplier/', params)
      : await api.put(`/supplier/${id}/`, params)

    setSupplier(json)

    const supplierID = json.id

    await saveShippingMethodMaps(supplierID, shipping_method_maps)

    showMessageToast(`${isNew ? 'Added' : 'Updated'} supplier successfully.`)

    return navigate([SETTINGS, SUPPLIERS])
  } catch (err) {
    updateSuppliersForm({
      serverError: err,
    })
  }

  updateSuppliersForm({
    isSaving: false,
  })
}

export async function saveShippingMethodMaps(supplierID, shipping_method_maps) {
  const supplier = supplierSelector(getState(), {supplierID})

  const og_shipping_method_maps = supplier.shipping_method_maps

  if (!og_shipping_method_maps) {
    return
  }

  const properties = [
    'active',
    'requested_shipping_method',
    'mapped_shipping_method',
    'mapped_shipping_carrier',
    'mapped_shipping_account',
  ]

  const mapsToAdd = []
  const mapsToUpdate = []

  shipping_method_maps.forEach((shipping_method_map) => {
    if (!shipping_method_map.id) {
      mapsToAdd.push(shipping_method_map)

      return
    }

    const matchingExisting = og_shipping_method_maps.find(
      ({id}) => id === shipping_method_map.id,
    )

    if (
      !isEqual(
        pick(matchingExisting, properties),
        pick(shipping_method_map, properties),
      )
    ) {
      mapsToUpdate.push(shipping_method_map)
    }
  })

  const mapsToDelete = og_shipping_method_maps.filter(
    (shipping_method_map) =>
      !shipping_method_maps.find(({id}) => id === shipping_method_map.id),
  )

  await Promise.all(
    mapsToDelete.map(({id}) =>
      api.delete(`/supplier/${supplierID}/shipping_methods/${id}/`),
    ),
  )

  await Promise.all(
    mapsToUpdate.map(
      ({
        id,
        active,
        requested_shipping_method,
        mapped_shipping_method,
        mapped_shipping_carrier,
        mapped_shipping_account,
      }) =>
        api.put(`/supplier/${supplierID}/shipping_methods/${id}/`, {
          active,
          requested_shipping_method,
          mapped_shipping_method,
          mapped_shipping_carrier,
          mapped_shipping_account,
        }),
    ),
  )

  await Promise.all(
    mapsToAdd.map(
      ({
        active,
        requested_shipping_method,
        mapped_shipping_method,
        mapped_shipping_carrier,
        mapped_shipping_account,
      }) =>
        api.post(`/supplier/${supplierID}/shipping_methods/`, {
          active,
          requested_shipping_method,
          mapped_shipping_method,
          mapped_shipping_carrier,
          mapped_shipping_account,
        }),
    ),
  )

  if (mapsToAdd.length || mapsToUpdate.length || mapsToDelete.length) {
    const {json: supplier} = await api.get(`/supplier/${supplierID}`)

    setSupplier(supplier)
  }
}

export function localeForFBAVendor(vendor) {
  if (vendor === AMAZON_UK) {
    return 'UK'
  } else if (vendor === AMAZON_CA) {
    return 'CA'
  }

  return 'US'
}

export async function syncFBAInventory(supplierID) {
  const supplier = supplierSelector(getState(), {supplierID})

  if (isEmpty(supplier.vendor_config)) {
    return
  }

  const cart = supplier.vendor_config
  const locale = localeForFBAVendor(cart.vendor)

  const params = {
    cart_id: cart.id,
    name: `FBA ${locale} Warehouse (${cart.id})`,
    warehouse_type: WAREHOUSE_TYPE_FBA,
  }

  try {
    const {json} = await api.post('/warehouse/', params)

    await getCarts()

    setWarehouse(json)

    dispatch(checkRunningTasks())
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while syncing FBA inventory.',
        details: err.message || err.error_message,
      },
      err,
    )
  }
}

export async function syncAWDInventory(supplierID) {
  const supplier = supplierSelector(getState(), {supplierID})

  if (isEmpty(supplier.vendor_config)) {
    return
  }

  const cart = supplier.vendor_config

  const params = {
    cart_id: cart.id,
    name: `AWD Warehouse (${cart.id})`,
    warehouse_type: WAREHOUSE_TYPE_AWD,
  }

  try {
    const {json} = await api.post('/warehouse/', params)

    await getCarts()

    setWarehouse(json)

    dispatch(checkRunningTasks())
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while syncing AWD inventory.',
        details: err.message || err.error_message,
      },
      err,
    )
  }
}
